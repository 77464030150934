import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  phoneNumber: (isClicked: boolean) => ({
    cursor: 'pointer',
    color: isClicked ? colors.secondaryInverseText : colors.primaryIcon,
    display: {
      default: 'flex',
      '@media (max-width: 767px)': 'none',
    },
    alignItems: 'center',
  }),
  phoneIcon: (isClicked: boolean) => ({
    stroke: isClicked ? colors.secondaryInverseText : colors.primaryIcon,
    fill: 'none',
    display: 'none',
    '@media (max-width: 767px)': {
      ':not(#__unused__) > svg': {
        width: '8.533rem',
        height: '8.533rem',
      },
      display: 'flex',
      alignItems: 'center',

      cursor: 'pointer',
    },
  }),
});
