import { useEffect, useMemo, useState } from 'react';
import BeforeAfterOneSlide from './before-after-one-slide/ui';
import PhotoSlider from './before-after-one-slide/ui/preview-slider.tsx';
import ArrowsForSlides from '@/features/before-after-slider/ui/arrows-for-slides/arrows-for-slides.tsx';
import BuildingSwitcher from '@/features/before-after-slider/ui/building-switcher/building-switcher.tsx';
import { photoType } from '@/features/before-after-slider/model/types.ts';

import styles from '@/features/before-after-slider/ui/index.module.css';

import { BEFORE_AFTER_PHOTO_INFO_MOCK } from '@/shared/mockData/realisation-page/realisation-page-mock-data.ts';

const BeforeAfterSlider = ({ photosInfo }: { photosInfo: typeof BEFORE_AFTER_PHOTO_INFO_MOCK }) => {
  const [currentImgIndex, setCurrentImgIndex] = useState<number>(0);
  const [photos, setPhotos] = useState<photoType[]>([]);

  const buildingIdsDictionary = useMemo(() => {
    return photosInfo.reduce(
      (acc, elem) => {
        acc[elem.id] = [...elem.photos.map((el) => el.id)];
        return acc;
      },
      {} as Record<string, number[]>
    );
  }, []);

  useEffect(() => {
    setPhotos(
      [...photosInfo].reduce((acc, el) => {
        return [
          ...acc,
          {
            id: el.id,
            title: el.buildingName,
            logoImg: el.logoImg,
            isLogo: true,
            isDisabled: el.isDisabled,
          },
          ...el.photos.map((elem) => ({ ...elem, parentId: el.id })),
        ];
      }, [] as photoType[])
    );
  }, []);

  useEffect(() => {
    setCurrentImgIndex(photos.filter((el) => !el.isLogo)?.[0]?.id);
  }, [photos]);

  return (
    <div className={styles.container}>
      <BuildingSwitcher
        currentImgIndex={currentImgIndex}
        setCurrentImgIndex={setCurrentImgIndex}
        photos={photos}
        buildingIdsDictionary={buildingIdsDictionary}
      />
      <ArrowsForSlides
        photos={photos}
        setCurrentImgIndex={setCurrentImgIndex}
        buildingIdsDictionary={buildingIdsDictionary}
      >
        <>
          {photos
            .filter((item) => !item.isLogo)
            .map((item, index) => (
              <BeforeAfterOneSlide
                key={index}
                beforeImage={{ imageUrl: item.beforeImg ?? '' }}
                afterImage={{ imageUrl: item.afterImg ?? '' }}
                isHidden={item.id !== currentImgIndex}
              />
            ))}
        </>
      </ArrowsForSlides>
      <PhotoSlider photos={photos} currentIndex={currentImgIndex} setCurrentIndex={setCurrentImgIndex} />
    </div>
  );
};

export default BeforeAfterSlider;
