import { LocationData } from '@/features/contacts-content/model/contacts-data-types';
import smxBuild from '@pages/contact-page/model/office-photo/smx-build.png';
import dostizheniye from '@pages/contact-page/model/office-photo/dostizheniye.png';
import frunzenskaya from '@pages/contact-page/model/office-photo/frunzenskaya.png';
import lifeTime from '@pages/contact-page/model/office-photo/life-time.png';
import tishynka from '@pages/contact-page/model/office-photo/tishynka.png';
import westerdam from '@pages/contact-page/model/office-photo/westerdam.png';
import westGarden from '@pages/contact-page/model/office-photo/west-garden.png';

export const locationData: LocationData[] = [
  {
    title: 'Штаб-квартира Sminex',
    address: 'Кадашёвская наб., 6/1/2с1',
    previewUrl: smxBuild,
    walkModeUrl: 'https://biganto.ru/tour/12729/',
    schedule: 'Понедельник - пятница с 10:00 до 20:00',
    metro: ['м. «Третьяковская» выход 2 – 700 м.', 'м. «Полянка» выход 1 – 800 м.'],
    parking: 'Парковка городская 380 руб/час',
    email: 'contact-center@sminex.com',
    type: 'building',
    coords: [55.742975, 37.617662],
  },
  {
    title: 'Дом продаж Фрунзенская набережная',
    address: 'Фрунзенская набережная, д. 36/2',
    previewUrl: frunzenskaya,
    schedule: 'Понедельник-воскресенье с 9:00 до 21:00',
    metro: ['м. «Фрунзенская» выход 1 – 450 м.'],
    parking: 'Парковка для клиентов наземная у дома продаж',
    email: '',
    type: 'building',
    coords: [55.722142, 37.584757],
  },
  {
    title: 'Дом продаж Достижение',
    address: 'ул. Академика Королева, д. 21',
    previewUrl: dostizheniye,
    walkModeUrl: 'https://biganto.ru/tour/12725/',
    schedule: 'Понедельник-воскресенье с 9:00 до 21:00',
    metro: ['м. «Бутырская» выход 1 – 700 м.','м. «Фонвизинская» выход 3 – 1300 м.'],
    parking: 'Парковка для клиентов под шлагбаумом',
    email: '',
    type: 'building',
    coords: [55.819909, 37.60231],
  },

  {
    title: 'Дом продаж LIFE TIME',
    address: 'ул. Сергея Макеева, д. 9, стр. 3',
    previewUrl: lifeTime,
    walkModeUrl: 'https://biganto.ru/tour/12728/',
    schedule: 'Понедельник-воскресенье с 9:00 до 21:00',
    metro: ['м. «Улица 1905 года» выход 1 – 1 км.'],
    parking: 'Парковка для клиентов подземная в ЖК Red Side',
    email: '',
    type: 'building',
    coords: [55.762151, 37.550091],
  },
  {
    title: 'Дом продаж Тишинский бульвар',
    address: 'Средний Тишинский переулок, д. 5',
    previewUrl: tishynka,
    schedule: 'Понедельник-воскресенье с 9:00 до 21:00',
    metro: ['м. «Белорусская» выход 1 – 800 м.'],
    parking: 'Бесплатная парковка для клиентов',
    email: '',
    type: 'building',
    coords: [55.769965, 37.577256],
  },
  {
    title: 'Дом продаж West Garden',
    address: 'ул. Минская, д.1ГсА',
    previewUrl: westGarden,
    walkModeUrl: 'https://biganto.ru/tour/12722/',
    schedule: 'Понедельник-воскресенье с 9:00 до 21:00',
    metro: ['м. «Минская» выход 2 – 950 м.'],
    parking: 'Парковка городская бесплатная',
    email: '',
    type: 'building',
    coords: [55.715758, 37.502363],
  },
  {
    title: 'Дом продаж Вестердам',
    address: 'Аминьевское шоссе, д. 4А',
    previewUrl: westerdam,
    walkModeUrl: 'https://biganto.ru/tour/12723/',
    schedule: 'Понедельник-воскресенье с 9:00 до 21:00',
    metro: ['м. «Аминьевская» выход 7 - 520 м.'],
    parking: 'Парковка для клиентов под шлагбаумом',
    email: '',
    type: 'building',
    coords: [55.699548, 37.462541],
  },

  {
    title: 'Пресс-служба',
    email: 'press@sminex.com',
    phone: '+7 968 643 30 36',
    type: 'other',
    coords: [55.7429, 37.6208],
  },
  {
    title: 'Служба комфорта',
    email: 'maintenance@sminex.com',
    phone: '+7 495 644 40 00',
    type: 'other',
    coords: [55.7429, 37.6208],
  },
  {
    title: 'Покупка площадок',
    email: 'invest@sminex.com',
    phone: '+7 495 152 43 02',
    type: 'other',
    coords: [55.7429, 37.6208],
  },
  {
    title: 'Кадры',
    email: 'hr@sminex.com',
    phone: '+7 495 453 68 54',
    type: 'other',
    coords: [55.7429, 37.6208],
  },
  {
    title: 'Тендеры',
    contacts: [
      {
        sectionTitle: 'По закупкам подрядных работ и услуг',
        phone: '+7 495 152 43 02 доб. 2280',
        email: 'tenders@sminex.com',
      },
      {
        sectionTitle: 'По закупкам материалов и оборудования',
        phone: '+7 495 152 43 02 доб. 2281',
        email: 'tenders@sminex.com',
      },
    ],
    email: '',
    type: 'other',
    coords: [55.7429, 37.6208],
  },
  {
    title: 'Линия доверия',
    contacts: [
      {
        sectionTitle:
          ['Sminex придерживается принципов честного и этичного ведения бизнеса, которые закреплены в корпоративном кодексе. Они распространяются на всех сотрудников и контрагентов.',' Вы можете сообщить о случаях нарушения этики, мошенничестве, хищениях или фактах коррупции со стороны сотрудников компании. Мы внимательно изучим каждый случай, идущий вразрез с корпоративными принципами.'],
        phone: '+7 965 320 2640',
        email: 'doverie@sminex.com',
      },
    ],
    email: '',
    type: 'other',
    coords: [55.7429, 37.6208],
  },
];
