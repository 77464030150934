import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { useEffect, useRef, useState } from 'react';
import { Play } from '@/shared/ui/icons/Play';
import { Modal } from '@/shared/ui/modal';

interface VideoProps {
  previewUrl: string;
  videoUrl:string;
}

export const Video = ({ previewUrl,videoUrl }: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true;
      videoRef.current.play().catch((error) => {
        console.log('Autoplay was prevented:', error);
      });
    }
  }, []);

  const handlePlay = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div {...stylex.props(styles.videoContainer)}>
      <video
        ref={videoRef}
        src={previewUrl}
        autoPlay={true}
        controls={false}
        loop={true}
        {...stylex.props(styles.video)}
      ></video>
      <div {...stylex.props(styles.btnWrap)}>
        <button
          onClick={handlePlay}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          {...stylex.props(styles.playButton, isHover && styles.playButtonHover)}
        >
          <Play />
        </button>
        <span {...stylex.props(styles.btnText)}>Смотреть видео</span>
      </div>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <iframe
          {...stylex.props(styles.iframe)}
          src={videoUrl}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Modal>
    </div>
  );
};
