import { ISectionItem } from '../model/types';

export const mockSections: ISectionItem[] = [
  {
    header: 'ЦЕННОСТИ КОМПАНИИ',
    description: '', 
    list: [
      'Мы создаем лучший на рынке продукт, вкладывая душу в каждый дом и тщательно прорабатывая каждую деталь.',
      'Мы держим своё слово — выполняем взятые на себя обязательства перед сотрудниками, клиентами и контрагентами.',
      'У нас демократический стиль выработки решений, где каждый может быть услышан.',
      'Мы делаем всё возможное, чтобы выполнить задание/работу в срок.',
      'Мы не позволяем сотрудникам воровать, получать откаты, портить рабочий климат.',
    ],
    id: 'Ценности компании'
  },
  {
    header: 'КОМПАНИЯ МЕЧТЫ',
    description: 'Мы стремимся стать для сотрудников компанией мечты, работать в которой престижно и комфортно. Наша цель, чтобы работа в 5nineX была хорошо оплачиваемым хобби. Мы делаем многое, чтобы к нам приходили лучшие и все получали удовольствие от работы. Для таких амбициозных целей мы обеспечиваем следующие условия:',
    list: [
      'Строим красивые дома, на голову лучше конкурентов.',
      'Создаем самую комфортную и престижную среду для клиентов.',
      'Создаем в компании атмосферу взаимного уважения.',
      'В работе применяем прозрачные, разумные, логичные правила.',
      'Не допускаем в компанию самодуров, бездельников, воров и интриганов.',
      'Платим за результаты и выше рынка без верхнего предела.',
      'Даём каждому принять участие в развитии и улучшении компании.',
      'Обеспечиваем комфортные рабочие места и места общего пользования.',
      'А ещё учим, кормим, развлекаем и заботимся каждый день обо всех сотрудниках.',
    ],
    id: 'Компания мечты'

  },
  {
    header: `СОТРУДНИКИ МЕЧТЫ`,
    description: 'Ниже указаны качества, которые мы ценим в сотрудниках. Именно таких сотрудников мы ищем и привлекаем в Sminex.',
    list: [
      'Целеустремлённость — постановка перед собой амбициозных и достижимых целей.',
      'Результативность — достижение поставленных целей с максимально высоким качеством.',
      'Ориентированность на бизнес-результат — выполняя свою работу, думать о конечной цели дела, умении видеть ситуацию в комплексе.',
      'Ответственность — отвечать за свои решения, действия и результаты. Выполнять обещания. Не искать причины неуспеха в других людях и внешних обстоятельствах. Следовать принятым на себя обязательствам.',
      'Системность — последовательность, организованность, умение планировать, ориентироваться на факты.',
      'Желание зарабатывать вместе с компанией — желание получать деньги именно за ту работу, которая действительно нужна компании.',
      'Инновационность — постановка под сомнение традиционных методов. Поиск свежих и оригинальных решений задач.',
      'Инициативность — предложения по улучшению компании. Желание брать на себя их реализацию.',
      'Командность — эффективное взаимодействие и оказание поддержки другим сотрудникам. Выстраивание устойчивых рабочих взаимоотношений. Терпимость к другим, стремление к взаимопониманию.',
      'Корпоративность — разделение миссии, принципов и целей компании. Соблюдение принципов и правил компании, разъединение целей и способов их достижения.',
    ],
    id: `Сотрудники мечты`

  },
];
