import { useEffect, useRef, useState } from 'react';
import styles from './contacts-content.module.css';
import baloon from '@shared/ui/assets/smxBaloon.svg';
import { WalkMode } from '@/features/walk-mode/ui';
import { ChevroneLeft } from '@/shared/ui/icons/ChevronLeft';
import { LocationData } from '../../model/contacts-data-types';

interface ContactsContentProps {
  data: LocationData | null;
  handleBackToMenu: () => void;
}

export const ContactsContent = ({ data, handleBackToMenu }: ContactsContentProps) => {
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<any>(null);
  const placemarkRef = useRef<any>(null); 
  const [isWalkModeVisible, setIsWalkModeVisible] = useState(false);

  useEffect(() => {
    if (data && mapContainerRef.current) {
      const ymaps = (window as any).ymaps;

      ymaps.ready(() => {
        if (!mapRef.current) {
          mapRef.current = new ymaps.Map(mapContainerRef.current, {
            center: data.coords,
            zoom: 14,
            controls: ['zoomControl', 'fullscreenControl', 'typeSelector'],
            autoFitToViewport: 'always',
          });

          placemarkRef.current = new ymaps.Placemark(
            data.coords,
            {},
            {
              iconLayout: 'default#imageWithContent',
              iconImageHref: baloon,
              iconImageSize: [70, 70],
              iconImageOffset: [-35, -35],
            }
          );

          mapRef.current.geoObjects.add(placemarkRef.current);
          mapRef.current.behaviors.disable('scrollZoom');

          // Применяем черно-белый фильтр сразу при создании карты
          mapRef.current.container.getElement().style.filter = 'grayscale(100%)';

          mapRef.current.controls.get('fullscreenControl').events.add('fullscreenenter', () => {
            mapRef.current.container.getElement().style.filter = 'grayscale(100%)';
          });

          mapRef.current.controls.get('fullscreenControl').events.add('fullscreenexit', () => {
            mapRef.current.container.getElement().style.filter = 'grayscale(100%)';
          });
        } else {
          mapRef.current.setCenter(data.coords);
          placemarkRef.current.geometry.setCoordinates(data.coords);
          mapRef.current.container.fitToViewport();
        }
      });
    }

    // Очистка карты при размонтировании компонента
    return () => {
      if (mapRef.current) {
        mapRef.current.destroy();
        mapRef.current = null;
      }
    };
  }, [data]);

  return (
    <div className={`${styles.contentContainer}`}>
      {data !== null && (
        <button onClick={handleBackToMenu} className={styles.backButton}>
          <span className={styles.backIcon}>
            <ChevroneLeft />
          </span>
          {data?.title}
        </button>
      )}
      <div className={`${styles.contentWrapper} ${styles.contentWrapperVisible}`}>
        {data?.type === 'building' && (
          <div className={styles.contentWrapperBlock}>
            {data.address && <h3 className={styles.contentTitle}>{data.address}</h3>}
            {data.schedule && <p>{data.schedule}</p>}
          </div>
        )}
        {data?.metro && (
          <div className={styles.contentWrapperBlock}>
            <h3 className={styles.contentTitle}>Как добраться?</h3>
            {data.metro.map((metroStation, index) => (
              <p key={index}>{metroStation}</p>
            ))}
          </div>
        )}
        {data?.parking && (
          <div className={styles.contentWrapperBlock}>
            <h3 className={styles.contentTitle}>Парковка:</h3>
            <p>{data.parking}</p>
          </div>
        )}
        {data?.phone && <p className={styles.phone}>{data.phone}</p>}
        {data?.email && (
          <>
            <h3 className={styles.contentTitle}>{data.email}</h3>
          </>
        )}
        {data?.type === 'building' && (
          <div className={styles.contentWrapperBlock}>
            <>
              {data.email && (
                <a
                  href="https://www.sminex.com/upload/iblock/4e4/28nhsaf4wfxmddl29km1ykou6dns9rao/Sminex.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Скачать схему проезда
                </a>
              )}
              <a href={`https://yandex.ru/maps/?rtext=~${data.address}`} target="_blank" rel="noopener noreferrer">
                Построить маршрут
              </a>
            </>
          </div>
        )}
        {data?.contacts &&
          data.contacts.map((contact, index) => (
            <div key={index} className={styles.sectionWrapper}>
              {Array.isArray(contact.sectionTitle) ? (
                contact.sectionTitle.map((title, index) => (
                  <h5 key={index} className={styles.sectionTitleLine}>
                    {title}
                  </h5>
                ))
              ) : (
                <h5 className={styles.sectionTitle}>{contact.sectionTitle}</h5>
              )}
              {contact.phone && <p>{contact.phone}</p>}
              {contact.email && <p>{contact.email}</p>}
            </div>
          ))}
      </div>

      <div className={`${styles.mapContainer}`}>
        {data?.previewUrl && (
          <div className={`${styles.topImageWrap} `} style={{ backgroundImage: `url(${data.previewUrl})` }}>
            {data.walkModeUrl && (
              <button className={styles.topImageBtn} onClick={() => setIsWalkModeVisible(true)}>
                Смотреть фототур
              </button>
            )}
          </div>
        )}
        <div className={styles.mapWrapper}>
          <div ref={mapContainerRef} className={styles.map} id={`map-${data?.coords}`}></div>
        </div>
      </div>

      {data?.walkModeUrl && (
        <WalkMode onClose={() => setIsWalkModeVisible(false)} isOpen={isWalkModeVisible} url={data.walkModeUrl} />
      )}
    </div>
  );
};
