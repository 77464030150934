import { ComponentConfig } from "@/pages/employer-page/model/employer-page-types";
import { locationData } from "./location-data-mock";
import { Dispatch, SetStateAction } from "react";
import { CommonProps } from "@/shared/libs/dynamic-components-render.tsx";

export const contactsPage = (
  selectedIndex: number,
  setSelectedIndex: Dispatch<SetStateAction<number>>,
  setIsVisible: Dispatch<SetStateAction<boolean>>,
  isMobile: boolean
): ComponentConfig<CommonProps>[] => [
  {
    component: "ContactsMenuItemList",
    props: {
      selectedIndex: selectedIndex,
      locationData: locationData,
      setSelectedIndex,
      setIsVisible
    }
  },
  {
    component: "ContactsContentList",
    props: {
      selectedIndex: selectedIndex,
      locationData: locationData,
      setSelectedIndex,
      isMobile: isMobile
    }
  }
];
