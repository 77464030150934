import { ProfileCardType } from "@/enteties/profile-card/model/types";
import { useRef } from "react";
import styles from './vice-presidents-list.module.css'
import { ProfileVicePresidentCard } from "@/enteties/profile-card/ui/vice-president-card";
import { AllExperienceCard } from "@/enteties/all-experience-card";

interface VicePresidentsListProps {
    mockEmployers:ProfileCardType[]
    expandedCardId: string | null
    handleToggleExpand: (e:string) => void
}

export const VicePresidentsList = ({mockEmployers,handleToggleExpand,expandedCardId}:VicePresidentsListProps) => {
    const cardsRef = useRef<(HTMLDivElement | null)[]>([]);
    const containerRef = useRef<HTMLDivElement>(null);
  
    return (

        <div className={styles.vicePresidentsWrapper}>
          <div ref={containerRef} className={styles.vicePresidentsContainer}>
            {mockEmployers.map((item, index) => (
              <div
                ref={(el) => (cardsRef.current[index] = el)}
                key={item.id}
                className={`${item.type !== 'profileCard' ? styles.experienceCard : ''} ${
                  item.id === '0' ? styles.firstCard : ''
                } ${item.id === '4' ? styles.card4 : ''} ${item.id === '5' ? styles.card5 : ''} ${
                  item.id === '6' ? styles.card6 : ''
                }`}
              >
                {item.type === 'profileCard' ? (
                  <ProfileVicePresidentCard
                    containerRef={containerRef}
                    item={item}
                    isExpanded={expandedCardId === item.id}
                    onToggleExpand={() => handleToggleExpand(item.id)}
                    getCard={() => cardsRef.current[index]}
                  />
                ) : (
                  <AllExperienceCard data={item} />
                )}
              </div>
            ))}
          </div>
        </div>
    )
}