import { useNavigate } from 'react-router-dom';
import styles from './menu.module.css';
import { menuSocMedia } from '@/shared/mockData/menu-mock-data';

export interface MenuItemType {
  id: number;
  title: string;
  url?: string;
  children?: MenuItemType[];
}

export interface MenuProps {
  menuContent: MenuItemType[];
  onClose: () => void; 
}

export const DesktopMenu = ({ menuContent, onClose }: MenuProps) => {
  const navigate = useNavigate();

  const filteredMenuContent = menuContent.flatMap((item) => {
    if (item.title === 'Информация' && item.children) {
      return item.children;
    }
    return item.title !== 'Информация' ? [item] : [];
  });

  const handleMenuClick = (url: string | undefined) => {
    if (url) {
      
      onClose();
      setTimeout(() => {
        navigate(url); 
      }, 100); 
    }
  };

  return (
    <div className={`${styles.menuContainer} ${styles.desktop}`}>
      <ul className={styles.menuContentWrapper}>
        {filteredMenuContent.map((item) => (
          <li key={item.id} className={styles.menuContentItem} onClick={() => handleMenuClick(item.url)}>
            {item.title}
          </li>
        ))}
      </ul>
      <div className={styles.menuContentIconWrapper}>
        {menuSocMedia &&
          menuSocMedia.map((el) => (
            <div key={el.id} className={styles.menuContentIcon}>
              {el.icon}
            </div>
          ))}
      </div>
    </div>
  );
};
