import { ComponentConfig } from '@/pages/employer-page/model/employer-page-types.ts';
import { CommonProps } from '@/shared/libs/dynamic-components-render.tsx';

import { BEFORE_AFTER_PHOTO_INFO_MOCK } from '@/shared/mockData/realisation-page/realisation-page-mock-data.ts';

export const RealisationPageConfig = (): ComponentConfig<CommonProps>[] => [
  {
    component: 'BeforeAfterSlider',
    props: {
      photosInfo: BEFORE_AFTER_PHOTO_INFO_MOCK,
    },
  },
];
