import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { PhoneIcon } from '@/shared/ui/icons/PhoneIcon';

interface PhoneNumberProps {
  phone: string;
  isMenuVisible: boolean;
}

export const PhoneNumber = ({ phone, isMenuVisible }: PhoneNumberProps) => {
  return (
    <>
      <p {...stylex.props([styles.phoneNumber(isMenuVisible)])}>{phone}</p>
      <div {...stylex.props([styles.phoneIcon(isMenuVisible)])}>
        <PhoneIcon />
      </div>
    </>
  );
};
