import { renderComponent } from '@/shared/libs/dynamic-components-render.tsx';
import { infoCenterAllNewsPageConfig } from '@/shared/mockData/info-center/info-center-all-news-mock.ts';
import { Fragment, useEffect, useState } from 'react';
import { getAllCategories, getAllNews } from '@/pages/info-center-all-news-page/api/data.ts';
import { INewsItem } from '@/pages/info-center-all-news-page/model';
import { ICategoryItem } from '@/pages/info-center-all-news-page/ui/components/category-filter/model/types.ts';
import { useQueryParams } from '@/shared/hooks/useQueryParams.tsx';
import { QUERY_PARAM_ACTIVE_CATEGORY_NAME } from '@/shared/constants/queries.ts';

const InfoCenterAllNewsPage = () => {
  const [filters, setFilters] = useState<ICategoryItem[]>([]);
  const [allNews, setAllNews] = useState<INewsItem[]>([]);
  const [newsToShow, setNewsToShow] = useState<INewsItem[]>([]);

  const { get, searchParams } = useQueryParams();
  const [activeCat, setActiveCat] = useState<string | null>(get(QUERY_PARAM_ACTIVE_CATEGORY_NAME));

  useEffect(() => {
    setActiveCat(get(QUERY_PARAM_ACTIVE_CATEGORY_NAME));
  }, [searchParams]);

  useEffect(() => {
    const currentNews = getAllNews();
    const currentCats = getAllCategories();
    if (currentNews) setAllNews(currentNews);
    if (currentCats) setFilters(currentCats);
  }, []);

  useEffect(() => {
    setNewsToShow(allNews.filter((news) => (activeCat ? news.categoryId === activeCat : true)));
  }, [allNews, activeCat]);

  return infoCenterAllNewsPageConfig({ filters, news: newsToShow }).map((item, index) => (
    <Fragment key={index}>{renderComponent(item)}</Fragment>
  ));
};

export default InfoCenterAllNewsPage;
