import { useState, Fragment } from 'react';
import styles from '../desktop-menu/menu.module.css';
import { Next } from '@/shared/ui/icons/Next/Next';
import { ArrowLeft } from '@/shared/ui/icons/ArrowLeft';
import { ArrowRight } from '@/shared/ui/icons/ArrowRight';
import { MenuProps } from '../desktop-menu/desktop-menu';
import { useNavigate } from 'react-router-dom';

interface MenuItemType {
  id: number;
  title: string;
  url?: string | undefined;
  children?: MenuItemType[];
}

export const MobileMenu = ({ menuContent, onClose }: MenuProps) => {
  const [currentItems, setCurrentItems] = useState<MenuItemType[]>(menuContent);
  const [navigationStack, setNavigationStack] = useState<MenuItemType[][]>([]);
  const navigate = useNavigate();

  const handleItemClick = (item: MenuItemType) => {
    if (item.children && item.children.length > 0) {
      setNavigationStack([...navigationStack, currentItems]);
      setCurrentItems(item.children);
    }
  };
  const handleMenuClick = (url: string | undefined) => {
    if (url) {
      onClose();
      setTimeout(() => {
        navigate(url);
      }, 100);
    }
  };
  const handleBackClick = () => {
    const previousItems = navigationStack.pop();
    if (previousItems) {
      setCurrentItems(previousItems);
      setNavigationStack([...navigationStack]);
    }
  };

  const renderMenuItems = (items: MenuItemType[]): JSX.Element[] => {
    return items.map((item) => (
      <Fragment key={item.id}>
        <div
          key={item.id}
          className={styles.menuContentItem}
          onClick={() => {
            handleItemClick(item);
            handleMenuClick(item.url);
          }}
        >
          {item.title}
          {item.children && item.children.length > 0 && (
            <span className={styles.arrow}>
              <ArrowRight />
            </span>
          )}
        </div>
      </Fragment>
    ));
  };

  return (
    <div className={`${styles.menuContainer} ${styles.mobile}`}>
      <div className={styles.menuContentWrapper}>
        {navigationStack.length > 0 && (
          <div className={styles.backButton} onClick={handleBackClick}>
            <div className={styles.backButtonIcon}>
              <ArrowLeft />
            </div>
            Назад
          </div>
        )}
        {renderMenuItems(currentItems)}
      </div>
      <div className={`${styles.menuNextBtn} ${navigationStack.length > 0 ? styles.menuNextBtnHidden : ''}`}>
        ВЫБРАТЬ НЕДВИЖИМОСТЬ
        <div className={styles.menuNextBtnIcon}>
          <Next isHovered={false} />
        </div>
      </div>
    </div>
  );
};
