import React, { MouseEventHandler, TouchEventHandler, useEffect, useRef, useState } from 'react';
import {
  BeforeAfterOneSlideProps,
  MODE,
} from '@/features/before-after-slider/ui/before-after-one-slide/model/types.ts';
import { useResizeFeel } from '@/features/before-after-slider/ui/before-after-one-slide/lib/useResizeFeel.tsx';
import { useReadyStatus } from '@/features/before-after-slider/ui/before-after-one-slide/lib/useReadyStatus.tsx';
import {
  computeNormalizedBrightness,
  isIntersectionObserverSupport,
  normalizeNewPosition,
} from '@/features/before-after-slider/ui/before-after-one-slide/lib/common.ts';
import { useInit } from '@/features/before-after-slider/ui/before-after-one-slide/lib/useInit.tsx';

import styles from './before-after-slider.module.css';

const DEFAULT_START_PERCENT = 50;
const DEFAULT_BACKGROUND_COLOR = '#fff';
const OBSERVER_VISIBLE_PERCENT = 0.95;
const OBSERVER_OPTIONS = {
  threshold: [0.0, OBSERVER_VISIBLE_PERCENT],
};

function BeforeAfterOneSlide({
  beforeImage,
  afterImage,
  className,
  withResizeFeel = true,
  currentPercentPosition,
  onVisible,
  onReady,
  onChangePercentPosition,
  feelsOnlyTheDelimiter = false,
  delimiterColor = DEFAULT_BACKGROUND_COLOR,
  onChangeMode,
  isHidden,
}: BeforeAfterOneSlideProps) {
  const classNames = [`${styles.beforeAfterSlider}`];
  className && classNames.push(className);

  const refContainer = useRef<HTMLDivElement>(null);
  const afterImageRef = useRef<HTMLImageElement>(null);

  const [imagesWidth, setImagesWidth] = useState<number | null>(null);
  const [delimiterPercentPosition, setDelimiterPosition] = useState(currentPercentPosition || DEFAULT_START_PERCENT);
  const [sliderMode, setSliderMode] = useState<MODE>(MODE.DEFAULT);
  const { onImageLoad, isReady } = useReadyStatus(imagesWidth, refContainer, onReady);
  const [containerPosition, setContainerPosition] = useState({
    top: 0,
    left: 0,
  });

  const onAfterImageLoad = () => {
    updateContainerWidth();
    onImageLoad(0);
  };

  /**
   * Observer start
   */
  const observerCallback = function (entries: IntersectionObserverEntry[]) {
    if (!observer || !onVisible) return;
    entries.forEach((entry) => {
      if (entry.intersectionRatio > OBSERVER_VISIBLE_PERCENT) {
        observer.disconnect();
        onVisible();
      }
    });
  };
  const [observer] = useState(
    onVisible && isIntersectionObserverSupport() ? new IntersectionObserver(observerCallback, OBSERVER_OPTIONS) : null
  );
  useEffect(() => {
    if (observer) {
      if (!isReady) return;
      observer.observe(refContainer.current as HTMLDivElement);
    }
  }, [isReady]);
  /**
   * Observer end
   */

  useEffect(() => {
    if (!currentPercentPosition || !imagesWidth) {
      return;
    }
    setDelimiterPosition(normalizeNewPosition(currentPercentPosition, imagesWidth));
  }, [currentPercentPosition, imagesWidth]);

  const updateContainerWidth = () => {
    if (!refContainer.current) return;
    const containerWidth = refContainer.current.offsetWidth;
    setImagesWidth(containerWidth);
  };

  const onMouseUpHandler = () => {
    setSliderModeProxy(MODE.DEFAULT);
  };

  const setSliderModeProxy = (newMode: MODE) => {
    setSliderMode(newMode);
    onChangeMode && onChangeMode(newMode);
  };

  useInit(updateContainerWidth, onMouseUpHandler, afterImageRef, onImageLoad);

  const imgStyles = !imagesWidth ? undefined : { width: `${imagesWidth}px` };
  const beforeImgContainerStyle = {
    width: `${delimiterPercentPosition}%`,
    filter: `brightness(${computeNormalizedBrightness('second', delimiterPercentPosition)}%)`,
  };

  const delimiterStyle = React.useMemo(
    () => ({
      left: `${delimiterPercentPosition}%`,
      backgroundColor: delimiterColor,
    }),
    [delimiterPercentPosition, delimiterColor]
  );

  const updateContainerPosition = () => {
    if (!refContainer.current) return;
    const containerCoords = refContainer.current.getBoundingClientRect();

    setContainerPosition({
      top: containerCoords.top + pageYOffset,
      left: containerCoords.left + pageXOffset,
    });
  };

  const onMouseDownHandler = () => {
    updateContainerPosition();
    setSliderModeProxy(MODE.MOVE);
  };

  const onMouseMoveHandler: MouseEventHandler<HTMLDivElement> = (e) => onMoveHandler(e);

  const onTouchMoveHandler: TouchEventHandler<HTMLDivElement> = (e) => {
    onMoveHandler(e.touches[0]);
  };

  const onMoveHandler = (e: React.Touch | React.MouseEvent) => {
    if (sliderMode === MODE.MOVE) {
      if (!imagesWidth) return;
      const X = e.pageX - containerPosition.left;
      const newPosition = (normalizeNewPosition(X, imagesWidth) / imagesWidth) * 100;
      onChangePercentPosition ? onChangePercentPosition(newPosition) : setDelimiterPosition(newPosition);
    }
  };

  useResizeFeel(updateContainerWidth, withResizeFeel);

  const onClickHandlers = {
    onMouseDown: onMouseDownHandler,
    onTouchStart: onMouseDownHandler,
  };

  return (
    <div
      ref={refContainer}
      className={`${styles.beforeAfterSlider} ${isHidden ? '' : styles.isActive}`}
      onMouseMove={onMouseMoveHandler}
      onTouchMove={onTouchMoveHandler}
      onTouchEnd={onMouseUpHandler}
      onTouchCancel={onMouseUpHandler}
      {...(!feelsOnlyTheDelimiter ? onClickHandlers : {})}
    >
      <div
        className={styles.beforeAfterSliderFirstPhotoContainer}
        style={{
          filter: `brightness(${computeNormalizedBrightness('first', delimiterPercentPosition)}%)`,
        }}
      >
        <img
          src={afterImage.imageUrl}
          onLoad={onAfterImageLoad}
          draggable={false}
          alt={afterImage.alt}
          ref={afterImageRef}
        />
        <span className={styles.leftImageText}>Фотография</span>
      </div>
      {Boolean(imagesWidth) && (
        <>
          <div className={styles.beforeAfterSliderSecondPhotoContainer} style={beforeImgContainerStyle}>
            <img
              style={imgStyles}
              src={beforeImage.imageUrl}
              onLoad={() => onImageLoad(1)}
              draggable={false}
              alt={beforeImage.alt}
            />
            <span className={styles.rightImageText}>Рендер</span>
          </div>
          <div
            className={styles.beforeAfterSliderDelimiter}
            style={delimiterStyle}
            {...(feelsOnlyTheDelimiter ? onClickHandlers : {})}
          >
            <div className={styles.leftPlate}>
              <span className={`${styles.leftPlateText} ${styles.textInversion}`}>Реальность</span>
            </div>
            <div className={styles.rightPlate}>
              <span className={styles.rightPlateText}>Проект</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default BeforeAfterOneSlide;
