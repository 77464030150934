import * as stylex from '@stylexjs/stylex';
import { palette } from './palette.stylex';
export const colors = stylex.defineVars({
  primaryText: { default: palette['grey-950']},
  primaryInverseText: { default: palette['greyWhite']},
  secondaryText: { default: palette['darked-70']},
  tertiaryText: { default: palette['darked-50']},
  ghostText: { default: palette['darked-30']},
  accentText: { default: palette['bronze-500']},
  successText: { default: palette['green-500']},
  warningText: { default: palette['yellow-500']},
  errorText: { default: palette['red-500']},
  infoText: { default: palette['blue-500']},
  primaryBackground: { default: palette['greyWhite']},
  deepBackground: { default: palette['grey-50']},
  primaryInverseBackground: { default: palette['grey-950']},
  secondaryBackground: { default: palette['grey-700']},
  tertiaryBackground: { default: palette['grey-300']},
  ghostBackground: { default: palette['grey-50']},
  accentBackground: { default: palette['bronze-500']},
  successBackground: { default: palette['green-50']},
  warningBackground: { default: palette['yellow-50']},
  errorBackground: { default: palette['red-50']},
  infoBackground: { default: palette['blue-50']},
  hoverBackground: { default: palette['darked-5']},
  activeBackground: { default: palette['darked-10']},
  overlayBackground: { default: palette['darked-50']},
  brandBackground: { default: palette['bronze-200']},
  brandGhostBackground: { default: palette['bronze-50']},
  primaryBorder: { default: palette['darked-40']},
  inverseBorder: { default: palette['lighted-40']},
  secondaryBorder: { default: palette['darked-20']},
  tertiaryBorder: { default: palette['darked-10']},
  ghostBorder: { default: palette['darked-5']},
  accentBorder: { default: palette['bronze-500']},
  hoverBorder: { default: palette['darked-10']},
  successBorder: { default: palette['green-500']},
  warningBorder: { default: palette['yellow-500']},
  errorBorder: { default: palette['red-500']},
  infoBorder: { default: palette['blue-500']},
  primaryIcon: { default: palette['grey-950']},
  inverseIcon: { default: palette['greyWhite']},
  secondaryIcon: { default: palette['darked-70']},
  tertiaryIcon: { default: palette['darked-50']},
  ghostIcon: { default: palette['darked-30']},
  accentIcon: { default: palette['bronze-500']},
  secondaryInverseText: { default: palette['lighted-70']},
});
