import { colors } from "@/shared/tokens/colors.stylex";
import * as stylex from "@stylexjs/stylex";

export const styles = stylex.create({
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000
  },
  modalContent: {
    position: "relative",
    width: "100%",
    height: "100%",
    backgroundColor: "#fff",
    overflow: "hidden"
  },

  closeButton: {
    ":not(#__unused__) > svg": {
      width: "2rem",
      height: "2rem"
    },
    position: "absolute",
    top: "10px",
    right: "20px",
    background: "none",
    border: "none",
    fontSize: "24px",
    cursor: "pointer",
    width: "72px",
    height: "72px",
    borderRadius: "50%",
    stroke: colors.primaryInverseText,
    backgroundColor: colors.primaryInverseBackground,
    color: colors.primaryIcon,
    lineHeight: 0,
    "@media (max-width: 767px)": {
      width: "44px",
      height: "44px",
      ":not(#__unused__) > svg": {
        width: "22px",
        height: "22px"
      }
    }
  }
});
