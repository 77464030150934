import React from 'react';
import { renderComponent } from '../lib/dynamic-components-render';
import { pageConfig } from '../model/mock-json';



export const BrokerPage = () => {
    return (
        <>
            {pageConfig().map((config, index) => (
                <React.Fragment key={index}>{renderComponent(config)}</React.Fragment>
            ))}
        </>
    );
};