import useIsMobile from '@/shared/hooks/useIsMobile.tsx';
import { IBuildingSwitcherProps } from '@/features/before-after-slider/model/types.ts';

import styles from '@/features/before-after-slider/ui/index.module.css';

const BuildingSwitcher = ({
  currentImgIndex,
  photos,
  setCurrentImgIndex,
  buildingIdsDictionary,
}: IBuildingSwitcherProps) => {
  const { isMobile } = useIsMobile();

  return (
    <div className={styles.titlesContainer}>
      {photos
        .filter((item) => item.isLogo)
        .map((item) => (
          <button
            className={`${styles.title} ${buildingIdsDictionary?.[item.id]?.includes(currentImgIndex) ? styles.isActive : ''} ${item.isDisabled ? styles.isDisabled : ''}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (item.isDisabled) return;
              setCurrentImgIndex(item.id + 1);
            }}
            disabled={item.isDisabled}
          >
            {isMobile ? <img src={item.logoImg} alt={item.title} /> : item.title}
          </button>
        ))}
    </div>
  );
};

export default BuildingSwitcher;
