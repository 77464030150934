import { mockEmployers } from "@/enteties/profile-card/model/mock-employers";
import imageUrl from "@/shared/ui/assets/bg-images/Developer.png";
import styles from "../ui/employer-page.module.css";
import { mockVideo } from "@/shared/mockData/mock-video";
import { mockSections } from "@/enteties/employer-section/model/mock-employer";
import { ComponentConfig } from "./employer-page-types";
import { CommonProps } from "@/shared/libs/dynamic-components-render.tsx";

export const pageConfig = (
  expandedCardId: string | null,
  handleToggleExpand: (id: string) => void
): ComponentConfig<CommonProps>[] => [
  {
    component: "section",
    props: { className: styles.navContainer },
    children: [
      {
        component: "CardWithFloatBtn",
        props: {
          btnName: "ОТКРЫТЫЕ ВАКАНСИИ",
          redirectUrl: "/",
          firstSentence: "Работа в Sminex основана на общих ценностях, культуре и целях.",
          secondSentence: "Мы вместе работаем, зарабатываем, развиваемся и отдыхаем.",
          title: `РАБОТОДАТЕЛЬ №${"\u00A0"}1`
        }
      },
      {
        component: "Navbar",
        props: {}
      }
    ]
  },
  {
    component: "Video",
    props: {
      previewUrl: mockVideo[0].previewLink,
      videoUrl: mockVideo[0].videoLink
    }
  },
  {
    component: "section",
    props: { className: styles.employerPageContainer },
    children: [
      {
        component: "SectionList",
        props: { mockSections: mockSections }
      },
      {
        component: "EmployerTitle",
        props: { title: "ПРАВЛЕНИЕ" }
      },
      {
        component: "ProfileCard",
        props: {
          items: mockEmployers[0],
          isExpanded: expandedCardId === mockEmployers[0].id,
          onToggleExpand: () => handleToggleExpand(mockEmployers[0].id)
        }
      },
      {
        component: "VicePresidentsList",
        props: {
          mockEmployers: mockEmployers,
          expandedCardId: expandedCardId,
          handleToggleExpand: handleToggleExpand
        }
      },
      {
        component: "EmployerTask",
        props: {
          text: "Наша ключевая задача — создание комфортных условий труда и прозрачной системы карьерного роста сотрудников.Если вам близки наши принципы, заполните заявку и станьте частью профессионального коллектива."
        }
      }
    ]
  },
  {
    component: "TransitionCard",
    props: {
      bgUrl: imageUrl,
      title: "СМОТРЕТЬ ВАКАНСИИ",
      link: "/"
    }
  }
];
